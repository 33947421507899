import React from "react";
import cn from 'classnames';

class LoaderButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleButtonClick = this._handleButtonClick.bind(this);
  }

  _handleButtonClick(e) {
    e.preventDefault();

    this.props.handleClick(e);
  }

  render() {
    const { label } = this.props;
    const classes = cn('button-loader', {
      'button-loader--loading': this.props.loading
    });

    const checkIcon = () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill="#FFFFFF">
        </path>
      </svg>
    );

    return (
      <button
        onClick={ this.handleButtonClick }
        className={ classes }
      >
        <span className="button-loader__label">
          { label || 'Button' }
        </span>

        <div className="button-loader__success">

          { checkIcon() } Sent
				</div>
        <div className="button-loader__bar" />
      </button>
    )
  }
}

export default LoaderButton;
