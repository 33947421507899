import React from "react";

import request from "../../shared/request";

import AssociateSignupForm from "./associate_signup_form";
import AssociateSignupConfirmation from "./associate_signup_confirmation";

class AssociateSignup extends React.Component {
  state = {
    isAuthenticating: false,
    isSuccessful: false,
    payload: {
      first_name: '',
      last_name: '',
      company_name: '',
      email: '',
      mobile_phone: ''
    }
  }

  getPayload() {
    return { signup: this.state.payload };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      isAuthenticating: true
    });

    request('/field/api/v1/signup', "POST", this.getPayload())
      .then((response) => {
        this.setState({
          isSuccessful: true,
          isAuthenticating: false
        });
      })
      .catch((error) => {
        throw(error);
      });
  }

  handleChange = (value, field) => {
    let { payload } = this.state;
    payload[field] = value;

    this.setState({
      payload
    });
  }
  
  isValid = () => {
    const obj = this.state.payload;
    return obj.first_name !== '' &&
          obj.last_name !== '' &&
          obj.email !== '' &&
          obj.mobile_phone !== '' &&
          obj.company_name !== '';
  }

  isFieldComplete = (field) => {
    let isComplete = false;
    const fieldVal = this.state.payload[field];

    switch (field) {
      case 'mobile_phone':
        isComplete = fieldVal.length === 10;
        break;
      case 'email':
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        isComplete = re.test(String(fieldVal).toLowerCase());
        break;
      default:
        isComplete = fieldVal !== '';
        break;
    }

    return isComplete;
  }

  render() {
    return (
      <section className="auth-container">
        {this.state.isSuccessful ?
          <AssociateSignupConfirmation
            firstName={this.state.payload.first_name}
          />
          :
          <AssociateSignupForm
            handleChange={this.handleChange}
            handleChangeFromValue={this.handleChangeFromValue}
            handleSubmit={this.handleSubmit}
            isAuthenticating={this.state.isAuthenticating}
            isFieldComplete={this.isFieldComplete}
            isValid={this.isValid()}
          />
        }
      </section>
    )
  }
}

export default AssociateSignup;
