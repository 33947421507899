import React from "react";

const AssociateSignupConfirmation = (props) => {
  const { firstName } = props;
  const checkIcon = () => (
    <div className="auth-confirm-check">
     <span className="fa fa-check" />
    </div>
  );

  return (
    <React.Fragment>
      <div className="card-content">
        <div className="auth-confirm-block">
          {checkIcon()}
          <h3>Welcome, {firstName}!</h3>
          <p>We will be in touch soon to activate your account.</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AssociateSignupConfirmation;
