import axios from "axios";

axios.defaults.withCredentials = true;

export const API = axios.create({
  baseURL: "",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  transformRequest: [
    (data, headers) => {
      const csrfTokenElement = document.getElementsByName("csrf-token");
      if (csrfTokenElement.length > 0) {
        headers["X-CSRF-TOKEN"] = csrfTokenElement[0].content;
      }
      if (headers["Content-Type"] === "application/json") {
        return JSON.stringify(data);
      }
      return data;
    }
  ]
});

const requestOptions = (url, method, headers) => {
  let csrfToken = document.getElementsByName("csrf-token");
  if (csrfToken.length > 0) {
    csrfToken = csrfToken[0].content;
  } else {
    csrfToken = null;
  }

  return {
    method,
    url,
    credentials: "include",
    headers: Object.assign(
      {},
      {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      headers
    )
  };
};

const request = (url, method, payload, headers = {}) => {
  let options = requestOptions(url, method, headers);
  options.data = payload;
  return axios(options);
};

export const get = (url, params, headers = {}) => {
  let options = requestOptions(url, "GET", headers);
  options.params = params;
  return axios(options);
};

export const get_html = (url, params, headers = {}) => {
  return get(
    url,
    params,
    Object.assign(
      {},
      {
        Accept: "text/html",
        "Content-Type": "text/html"
      },
      headers
    )
  );
};

export const post = (url, payload, headers = {}) => {
  return request(url, "POST", payload, headers);
};

export const put = (url, payload, headers = {}) => {
  return request(url, "PUT", payload, headers);
};

export const patch = (url, payload, headers = {}) => {
  return request(url, "PATCH", payload, headers);
};

export const destroy = (url, payload, headers = {}) => {
  return request(url, "DELETE", payload, headers);
};

export const patchWithUnchangedPayload = (url, payload, headers = {}) => {
  let options = requestOptions(url, "PATCH", headers);
  options.data = payload;
  return axios(options);
};

export default request;
