import React from "react";

import axiosRequest from "../../shared/request";

const csrfToken = document.getElementsByName('csrf-token')[0].content;
const request = axiosRequest(csrfToken);

class QuoteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
    this.handleCloseClick = this.props.handleCloseClick;
    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.handleFormSubmit = this.props.handleFormSubmit;
    this.childHandleFormSubmit = this.childHandleFormSubmit.bind(this);
  }

  childHandleFormSubmit(e) {
    e.preventDefault();
    this.handleFormSubmit(this.state.email);
  }

  _handleEmailChange(e) {
    this.setState({email: e.target.value});
  }

  render() {
    return (
      <div>
        <div className="modal quote-modal account-modal in" id="quote-modal" style={{ display: 'block', paddingRight: '15px' }}>
          <div className="modal-header">
            <h2>Get a free quote</h2>
            <button aria-label="Close" className="close" data-dismiss="modal" onClick={this.handleCloseClick} id="public-modal-close-btn" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="account-modal-content">
            { this.props.showQuoteEmailForm ?
              <form className="sign-in-form" onSubmit={this.childHandleFormSubmit}>
                <input autoFocus placeholder="Enter your email"
                  required="true" type="email" onChange={this.handleEmailChange}/>
                <button className="button button-secondary-light button-large" type="submit"
                  >Submit</button>
              </form>
              :
              <div>{this.props.submittedEmailMessage}</div>
            }
          </div>
          <div className="modal-cta forgot-pass-cta">
            <p>Already have an account?</p>
            <a data-dismiss="modal" data-target="#login-modal" data-toggle="modal">Log In</a>
          </div>
        </div>
        <div className="modal-backdrop in" onClick={this.handleCloseClick} />
      </div>
    );
  }
}

class QuoteForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      showQuoteOptions: false,
      showQuoteModal: false,
      enableQuoteModalButton: false,
      focusedInput: null,
      isValid: false,
      location: null,
      numberOfTrucks: null,
      truckType: null,
      showQuoteEmailForm: true
    }

    this.handleFormSubmit = this._handleFormSubmit.bind(this);
    this.handleOtherFieldChange = this._handleOtherFieldChange.bind(this);
    this.handleLocationChange = this._handleLocationChange.bind(this);
    this.showQuoteModal = this._showQuoteModal.bind(this);
    this.handleCloseClick = this._handleCloseClick.bind(this);
  }

  componentDidMount() {
    this.setupLocationPicker();
  }

  setupLocationPicker() {
    const placesAutocomplete = places({
      container: document.querySelector('#location'),
      type: 'address',
      templates: {
        value: function (suggestion) {
          return `${suggestion.name}, ${suggestion.city}, ${suggestion.administrative}`;
        }
      }
    });

    placesAutocomplete.on('change', e => this.handleLocationChange(e));
  }

  _handleCloseClick() {
    this.setState({
      showQuoteModal: false,
      showQuoteEmailForm: true,
      submittedEmailMessage: ''
    });
  }

  render() {
    const truckTypes = [
      { name: '6 Wheel', value: 1 },
      { name: 'Triaxle', value: 2 },
      { name: '10 Wheel', value: 3 },
      { name: '6 Wheel w/ tag', value: 4 },
      { name: 'Trailer-Dump', value: 5 },
      { name: 'Flat Deck Trailer', value: 6 },
      { name: 'Lowbed Trailer', value: 7 },
      { name: 'Flow Boy Triaxle', value: 8 },
      { name: 'Flow Boy Trailer Dump', value: 9 },
      { name: 'Triaxle w/ tag', value: 10 },
      { name: 'Ramp Truck', value: 11 },
      { name: 'Tractor Only', value: 11 }
    ];

    return (
      <div>
        <section className="quote-form-wrap">
          {!this.state.showQuoteOptions &&
            <form className="quote-form" autoComplete="off" onSubmit={this.showQuoteModal}>
            <input autoComplete="false" name="hidden" type="text" style={{display: 'none'}}/>
              <div className="form-group">
                <input type="text" id="location" name="location" className="form-control" placeholder="Enter Location" required
                autoComplete="off"/>
              </div>

              <div className="form-group">
                <select id="truck-type" name="truckType" className="form-control" required onChange={this.handleOtherFieldChange}>
                  <option value="">Truck Type</option>
                  {
                    this.props.truck_type_names.map((truck_type_name, i) => {
                      return (
                        <option key={i} value={truck_type_name}>{truck_type_name}</option>
                      )
                    })
                  }
                </select>
              </div>

              <div className="form-group">
                <input type="text" name="numberOfTrucks" className="form-control" placeholder="# of Trucks" required onChange={this.handleOtherFieldChange}/>
              </div>

              <div className="form-group">
                <button className="check-availability" type="submit" disabled={!this.state.enableQuoteModalButton}>
                  Check Availability
                </button>
              </div>
            </form>
          }
          {this.state.showQuoteOptions && this.getQuoteOptions()}
        </section>
        {this.state.showQuoteModal &&
          <QuoteModal
            showQuoteEmailForm={this.state.showQuoteEmailForm}
            submittedEmailMessage={this.state.submittedEmailMessage}
            handleCloseClick={this.handleCloseClick}
            handleFormSubmit={this.handleFormSubmit}/>}
      </div>

    );
  }

  _showQuoteModal(e) {
    e.preventDefault();

    this.setState({
      showQuoteModal: true
    });
  }

  _handleFormSubmit(email) {
    const payload = {
      inquiry: {
        location_json: JSON.stringify(this.state.location),
        truck_type: this.state.truckType,
        number_of_trucks: this.state.numberOfTrucks,
        email: email
      }
    };

    request(this.props.quote_url, "POST", payload).then(({data}) => {
      this.setState({
        showQuoteEmailForm: false,
        submittedEmailMessage: "Thank you for your message. We will be in touch!"
      });
    }).catch(({response}) => {
      this.setState({
        showQuoteEmailForm: false,
        submittedEmailMessage: response.data.message
      });
    });
  }

  _handleOtherFieldChange(e) {
    this.setState({[e.target.name]: e.target.value},
      this.checkQuoteModalButtonEnable)
  }

  _handleLocationChange(e) {
    this.setState({location: e.suggestion},
      this.checkQuoteModalButtonEnable)
  }

  checkQuoteModalButtonEnable() {
    this.setState(
      {
        enableQuoteModalButton: this.state.numberOfTrucks !== null &&
          this.state.truckTrype !== null &&
          this.state.location !== null
      }
    );
  }

}

export default QuoteForm;
