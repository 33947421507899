

import React, { useState, useEffect } from "react";
import { get, put } from "$shared/request";

const ShiftDriverNotificationsResponseContainer = ({ token, shift_driver_response_url }) => {
  const [shiftDriverResponse, setShiftDriverResponse] = useState(null);
  const [loadingShift, setLoadingShift] = useState(true);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const headersForRequest = {
    "content-type": "application/json",
    "x-hh-shift-notification-token": token,
  };

  useEffect(() => {
    get(shift_driver_response_url, {}, headersForRequest)
      .then(({ data: { shift_driver_response } }) => {
        // No shift_driver_response means all shifts have been responded
        if (shift_driver_response) {
          setShiftDriverResponse(shift_driver_response);
        } else {
          setSuccessMessage(window.I18n.t("broker.driver_confirmations.all_shifts_responded"));
        }
      })
      .catch(() => {
        setShiftDriverResponse(null);
        setErrorMessage(window.I18n.t("broker.driver_confirmations.invalid_link"));
      })
      .finally(() => {
        setLoadingShift(false);
      });
  }, []);

  const updateResponse = (response) => {
    setButtonsDisabled(true);

    response = response === "confirmed" ? "confirmed" : "rejected";
    const { id } = shiftDriverResponse;
    const payload = {
      shift_driver_responses: [{ id, response }],
    };

    put(shift_driver_response_url, payload, headersForRequest)
      .then(({ data: { next_shift_driver_response } }) => {
        // No next_shift_driver_response means all shifts have been responded
        if (!next_shift_driver_response) {
          setSuccessMessage(window.I18n.t("broker.driver_confirmations.all_shifts_responded"));
        }
        setShiftDriverResponse(next_shift_driver_response);
      })
      .catch(() => {
        setShiftDriverResponse(null);
        setErrorMessage(window.I18n.t("broker.driver_confirmations.invalid_link"));
      })
      .finally(() => {
        setButtonsDisabled(false);
      });
  };

  const errorMessageHtml = () => {
    return errorMessage && <div className="alert alert-dismissible alert-danger show text-center">{errorMessage}</div>;
  };

  const successMessageHtml = () => {
    return (
      successMessage && <div className="alert alert-dismissible alert-info show text-center">{successMessage}</div>
    );
  };

  const shiftDetailsHtml = () => {
    const shiftAttrList = [
      { label: window.I18n.t("generic.starts_at"), key: "starts_at" },
      { label: window.I18n.t("generic.customer"), key: "customer_name" },
      { label: window.I18n.t("generic.truck"), key: "truck_name" },
      { label: window.I18n.t("shifts.starting_location"), key: "starting_location" },
      { label: window.I18n.t("shifts.destination"), key: "destination" },
      { label: window.I18n.t("generic.fleet"), key: "fleet_name" },
      { label: window.I18n.t("generic.broker"), key: "broker_name" },
    ];
    return shiftAttrList.map((shiftAttr) => {
      return (
        <div key={shiftAttr.key} className="col-sm-6 col-xs-12 center-block">
          <div className="row">
            <div className="col-sm-6 col-xs-6 text-right">
              <h4>
                <span>{shiftAttr.label}:</span>
              </h4>
            </div>
            <div className="col-sm-6 col-xs-6 text-left">
              <h4>{shiftDriverResponse.shift[shiftAttr.key]}</h4>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="card-content">
      {errorMessageHtml()}
      {successMessageHtml()}
      {shiftDriverResponse && (
        <div className="row">
          <div className="text-center">
            <div className="card-tabs__link">Shift#{shiftDriverResponse.shift.id}</div>
          </div>
          {shiftDetailsHtml()}
          <div className="col-sm-12 text-center confirmation-buttons-container">
            <div className="row btn-group">
              <div className="col-md-6 col-xs-12">
                <button
                  className="btn btn-primary btn-lg big-button"
                  onClick={() => updateResponse("confirmed")}
                  disabled={buttonsDisabled}
                >
                  {window.I18n.t("broker.driver_confirmations.will_work")}
                </button>
              </div>
              <div className="col-md-6 col-xs-12">
                <button
                  className="btn btn-danger btn-lg big-button"
                  onClick={() => updateResponse("rejected")}
                  disabled={buttonsDisabled}
                >
                  {window.I18n.t("broker.driver_confirmations.cant_work")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {loadingShift && (
        <div className="row">
          <div className="text-center">
            <h4>{window.I18n.t("broker.driver_confirmations.loading_shift_data")}</h4>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShiftDriverNotificationsResponseContainer;
