import React from "react";

import request from "../../shared/request";

class RequestDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      companyName: '',
      message: '',
      showRequestDemoEmailForm: true
    };
    this.handleCloseClick = this._handleCloseClick.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
    this.handleFormSubmit = this._handleFormSubmit.bind(this);
  }

  _handleCloseClick() {
    this.setState({
      showRequestDemoEmailForm: true,
      submittedEmailMessage: '',
      name: '',
      email: '',
      companyName: '',
      message: ''
    });
  }

  _handleFormSubmit(e) {
    const payload = {
      request_demo: {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        company_name: this.state.companyName
      }
    };

    request(this.props.request_demo_url, "POST", payload).then(({data}) => {
      this.setState({
        showRequestDemoEmailForm: false,
        submittedEmailMessage: "Thank you for your message. We will be in touch!"
      });
    }).catch(({response}) => {
      this.setState({
        showRequestDemoEmailForm: false,
        submittedEmailMessage: response.data.message
      });
    });
    e.preventDefault();
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div className="modal request-demo-modal account-modal" id="request-demo-modal">
        <div className="modal-header">
          <h2>Request Demo</h2>
          <button aria-label="Close" className="close" data-dismiss="modal" id="public-modal-close-btn" type="button" onClick={this.handleCloseClick} >
            <span aria-hidden="true"> ×</span>
          </button>
        </div>
        <div className="account-modal-content">
          { this.state.showRequestDemoEmailForm ?
            <form id="request-demo-form" onSubmit={this.handleFormSubmit}>
              <input className="form-control" name="name" placeholder="Name *" required={true} type="text"
                value={this.state.name}
                onChange={this.handleInputChange}/>
              <input className="form-control" name="email" placeholder="Email address *" required={true} type="email"
                value={this.state.email}
                onChange={this.handleInputChange}/>
              <input className="form-control" name="companyName" placeholder="Company Name *" required={true} type="text"
                value={this.state.companyName}
                onChange={this.handleInputChange}/>
              <textarea className="form-control" name="message" placeholder="Message *" required={true} type="text"
                value={this.state.message}
                onChange={this.handleInputChange}>
              </textarea>
              <button className="button button-secondary-light button-large" type="submit">Submit</button>
            </form>
            :
            <div>{this.state.submittedEmailMessage}</div>
          }
        </div>
      </div>
    );
  }
}

export default RequestDemo;
