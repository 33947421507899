import React from "react";
import cn from 'classnames';
import NumberFormat from 'react-number-format';

const AssociateSignupForm = (props) => {
  const {
    handleSubmit,
    handleChange,
    handleChangeFromValue,
    isValid,
    isAuthenticating,
    isFieldComplete
  } = props;

  const checkIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24">
      <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill="#FFFFFF">
      </path>
    </svg>
  );

  return (    
    <form className="auth-form">
      <div className="form-row">
        <div className={cn('form-group', { 'form-group--complete': isFieldComplete('first_name') })}>
          <label htmlFor="firstName" className="form-label">First Name</label>
          <input
            type="text"
            id="firstName"
            className="form-control"
            onChange={(e) => handleChange(e.target.value, 'first_name')}
          />
        </div>

        <div className={cn('form-group', {'form-group--complete': isFieldComplete('last_name')})}>
          <label htmlFor="lastName" className="form-label">Last Name</label>
          <input
            type="text"
            id="lastName"
            className="form-control"
            onChange={(e) => handleChange(e.target.value, 'last_name')}
          />
        </div>
      </div>

      <div className={cn('form-group', { 'form-group--complete': isFieldComplete('email') })}>
        <label htmlFor="email" className="form-label">Email Address</label>
        <input
          type="email"
          id="email"
          className="form-control"
          onChange={(e) => handleChange(e.target.value, 'email')}
        />
      </div>

      <div className="form-row">
        <div className={cn('form-group', { 'form-group--complete': isFieldComplete('company_name') })}>
          <label htmlFor="companyName" className="form-label">Company</label>
          <input
            type="text"
            id="companyName"
            className="form-control"
            onChange={(e) => handleChange(e.target.value, 'company_name')}
          />
        </div>

        <div className={cn('form-group', { 'form-group--complete': isFieldComplete('mobile_phone') })}>
          <label htmlFor="mobilePhone" className="form-label">Mobile Phone</label>
          <NumberFormat
            format="(###) ###-####"
            mask="_"
            className="form-control"
            onValueChange={(values) => {
              const { value } = values;
              handleChange(value, 'mobile_phone');
            }}
          />
        </div>
      </div>

      <div className="form-group">
        <button onClick={handleSubmit} disabled={!isValid} className="button button-primary">
          {isAuthenticating ? 'Submiting...' : 'Submit'}
        </button>
      </div>
    </form>
  )
}

export default AssociateSignupForm;
