import React from "react";
import cn from 'classnames';
import axiosRequest from "../../shared/request";
import InputMask from 'react-input-mask';

import LoaderButton from './loader_button';

const csrfToken = document.getElementsByName('csrf-token')[0].content;
const request = axiosRequest(csrfToken);

class SmsAppLinkForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      hasSent: false,
      hasError: false,
      payload: {
        request: {
          phone: '',
          app: null,
          os: null
        }
      }
    }

    this.handleFormSubmit = this._handleFormSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
    this.handleInputFocus = this._handleInputFocus.bind(this);
  }

  _handleInputFocus(e) {
    this.setState({
      hasError: false
    });
  }

  _handleInputChange(e) {
    let { value } = e.currentTarget;

    this.setState({
      payload: {
        request: {
          phone: value,
          app: 'driver',
          os: 'iOS'
        }
      }
    });
  }

  render() {
    const successStyle = {
      background: 'green',
      color: 'white'
    }
    const errorStyle = {
      background: 'red',
      color: 'white'
    }

    const {
      loading,
      hasSent,
      hasError,
      payload
    } = this.state;

    const successMessage = () => (
      <div style={ successStyle }>
        Sent!
      </div>
    )

    const errorMessage = () => (
      <div style={ errorStyle }>
        There was a error
      </div>
    )

    return (
      <div className={cn('sms-app-link-form', { loading, hasSent, hasError }) }>
        <h5>Sign Up to Drive with HaulHub</h5>
        <form>          
          <InputMask
            value={ payload.request.phone }
            type="text"
            onFocus={this.handleInputFocus}
            onChange={this.handleInputChange}
            className="form-control"
            inputRef={(input) => { this.phoneInput = input; }}
            placeholder="Enter phone number"
            required
            mask="999 999 9999"
            maskChar=" "
          />

          <LoaderButton
            label="Send"
            handleClick={ this.handleFormSubmit }
            loading={ this.state.hasSent } 
          />
        </form>
        {/* { hasSent && successMessage() } */}

        {/* { hasError && errorMessage() } */}
      </div>
    );
  }

  _handleFormSubmit(e) {
    e.preventDefault();
    const { payload } = this.state;
    
    if (payload.request.phone.length > 0) {
      this.setState({
        loading: true,
      });

      setTimeout(() => {  
        request('/public/inquiries/sms_app_link', "POST", payload).then(({ data }) => {
          this.setState({
            loading: false,
            hasSent: true,
            payload: {
              request: {
                phone: '',
                app: null,
                os: null
              }
            }
          });
        }).catch(({ response }) => {
          this.setState({
            loading: false,
            hasSent: false,
            hasError: true
          });
        });
      }, 1000);
    } else {
      this.phoneInput.focus();
    }

  }
}

export default SmsAppLinkForm;
